import React, { Component } from 'react';
import axios from 'axios';
import JSONPretty from 'react-json-pretty';

class Checagem extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            checagem: "0",
            cpf:"",
            dt_check:"",
            result: {}
        };
        
    
        this.handleCPFChange = this.handleCPFChange.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this); 
      }
    

    render() { 
        return (
            <div style={{
                position: 'absolute', left: '5%', top: '5%',

            }}>
                <h1>Teste de Checagem Agrotools</h1>
                <div>Escolha o tipo da checagem</div>
                <div>
                    <select value={this.state.checagem}  onChange={this.checkTipo}>
                        <option value="0">IBAMA</option>
                        <option value="1">MTE</option>
                    </select>
                </div><br></br>
                <div>Digite o CPF ou CNPJ sem pontos ou traços</div>
                <div><input type="text" name="cpf" id="cpf"  onChange={this.handleCPFChange}/></div><br></br>
                <div>Digite a data no formato aaaa-mm-dd</div>
                <div><input type="text" name="dt_check" id="dt_check"  onChange={this.handleDataChange}/></div><br></br>
                
                <div><input type="button" value="Checar"  onClick={this.handleSubmit}/></div>
                <br></br>
                <hr></hr>
                <h2>Resultado{this.state.checagem === "0"?" - IBAMA":" - MTE"}</h2>
                
                <div><strong>Retorno do webservice: </strong>
                <JSONPretty id="json-pretty" data={this.state.result}></JSONPretty>
                </div>
            </div>
             
            
        );
    }

    checkTipo = (event) => {
        const {checagem} = this.state;
        this.setState({ checagem: event.target.value });
    }

    handleCPFChange = (event) => {
        this.setState({cpf: event.target.value});
    }

    handleDataChange = (event) => {
        this.setState({dt_check: event.target.value});
    }
    
    handleSubmit = (event) => {
        const {checagem, cpf, dt_check,result} = this.state;
        this.setState({result:{}})
        const prod = [{
            doc: cpf,
            consultdate: dt_check
        }];
        if(checagem === "0"){

            var data = JSON.stringify([{"doc":cpf,"consultdate":dt_check}]);

            var config = {
                method: 'post',
                url: 'https://ibama.atfunctions.com/api/ibama/consult_list_by_date/?IAMKEY=16DD65980FAB47BB47E8F1A1DD0A4C27106430EB90AC62A6386ADDFC0AA4AC82',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };

            axios(config).then(response => {
                this.setState({result: response.data});
                console.log(this.state.result)
            }).catch(error => {
                this.setState({result: error});
            });
        
        } else {
            var data = JSON.stringify([{"doc":cpf,"consultdate":dt_check}]);
            var config = {
                method: 'post',
                url: 'https://mte.atfunctions.com/api/mte/consult_list_by_date/?IAMKEY=16DD65980FAB47BB47E8F1A1DD0A4C27106430EB90AC62A6386ADDFC0AA4AC82',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };

            axios(config).then(response => {
                this.setState({result: response.data});
                console.log(this.state.result)
            }).catch(error => {
                this.setState({result: error});
            });

        }
        event.preventDefault();
    }

}
 
export default Checagem;